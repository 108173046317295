<template>
  <v-container class="pr-2" fluid>
    <div>
      <file-upload
          v-model="files"
          :multiple="multiple"
          :drop="drop"
          :drop-directory="true"
          ref="upload"
          @input="updateFiles"
          v-show="false"
      />
    </div>

    <label v-show="false" id="uploadLabel" ref="uploadLabel" for="file" style="cursor:pointer"/>
    <v-row v-if="showInfoMessage" justify="center" align="center" no-gutters
           style="cursor: pointer" class="pl-3 pb-2">
      <v-col cols="9\" @click="onAddFile()">
        <v-text-field prepend-icon="fas fa-paperclip" flat solo background-color="transparent" append-outer-icon="fas fa-plus"
                      :value="label" readonly/>
      </v-col>
      <v-col cols="3">
        <v-row style="padding-bottom: 30px; padding-left: 15px; padding-top: 20px">
          <span style="color: rgba(0, 0, 0, 0.38);" @click="onAddFile()">Adicionar</span>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pr-0" v-if="files.length">
      <v-col class="pl-4" cols="12">
        <v-chip v-for="(file) in files" :key="file.id"
                class="mr-2 mt-2"
                close
                outlined
                @click:close="$refs.upload.remove(file)">

          <span v-if="$resize && $mq.below(window.mobileSize)" style="max-width: 180px;"
                class="text-truncate d-inline-block">{{ file.name }}</span>
          <span v-else>{{ file.name }} ({{ file.size }})</span>

        </v-chip>
      </v-col>
      <v-col class="pt-0 pr-0 pl-3" cols="12">
        <GenericBtn
            color="tertiary"
            :on-click="() => ($refs.upload.clear())"
            :btn-props="{
              rounded: true,
              block: true,
              large: true
            }"
        >Limpar</GenericBtn>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FileUpload from 'vue-upload-component'
import {mapMutations, mapState} from "vuex";
import GenericBtn from "../../components/generic-btn/index"

export default {
  name: "CustomFileUpload",
  components: {
    FileUpload,
    GenericBtn,
  },
  props: {
    maximum: {
      type: Number,
      required: false,
      default: 0,
    },
    limitFileSize: {
      type: Number,
      required: false,
      default: 10,
    },
    showInfoMessage: {
      type: Boolean,
      required: false,
      default: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    drop: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: "Anexos",
    },
  },
  data: () => ({
    files: [],
    mouseOver: false,
  }),
  methods: {
    ...mapMutations(["showmsg"]),

    async updateFiles(files) {

      if (files.length > this.maximum) {
        this.showmsg({
          text: `Quantidade máxima de arquivos excedida, máximo de ${this.maximum} arquivos!`,
          type: "error",
          timeout: -1,
        });
        files.splice(this.maximum);
      }

      files = this.filterFilesByLimitSize(files);
      this.files = files;

      const promises = files.map(
          attachment => this.readFileContent(attachment.file),
      );

      const finalFiles = await Promise.all(promises);

      this.$emit('update', finalFiles);
    },
    readFileContent(file) {
      const fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(new DOMException("Unable to parse the file."));
        };

        fileReader.onload = () => resolve({
          name: file.name,
          body: fileReader.result,
          size: file.size,
        });

        fileReader.readAsDataURL(file);
      });
    },
    onAddFolder() {
      if (!this.$refs.upload.features.directory) {
        this.alert('Your browser does not support')
        return
      }
      let input = this.$refs.upload.$el.querySelector('input')
      input.directory = true
      input.webkitdirectory = true
      this.directory = true
      input.onclick = null
      input.click()
      input.onclick = (e) => {
        this.directory = false
        input.directory = false
        input.webkitdirectory = false
      }
    },
    onAddFile() {
      this.$refs.uploadLabel.click();
    },
    filterFilesByLimitSize(files) {
      if (this.limitFileSize > 0) {
        const filterFiles = files.filter(file => file.size <= this.limitFileSize * 1024 * 1024);

        if (filterFiles.length != files.length) {
          this.showmsg({
            text: `Tamanho máximo de arquivo excedido, máximo ${this.limitFileSize}MB!`,
            type: "error",
            timeout: -1,
          });
        }

        return filterFiles;
      } else {
        return files;
      }
    },
  },
  computed: {
    ...mapState(["window"]),
  }

}
</script>

<style scoped>

.fieldsetOutlined {
  border-radius: 4px;
  border-width: 0px;
  border-color: rgba(0, 0, 0, 0.38);
}

.fieldsetOutlined:hover {
  border-color: rgba(0, 0, 0, 0.8);
}

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}

.drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #ffffff;
  padding: 0;
}

</style>
